<template>
  <div class="order_import" ref="importContainer">
    <div class="order_import_list"
      v-if="orderList && orderList[0]"
      element-loading-text="拼命保存中..."
      v-loading="isImporting">
       <VirtualScrollList :size="216" :remain="remainCount" :onscroll="handleListScrolled" ref="refScroll">
        <div v-for="(item,index) in orderList"
          :key="item.__key__"
          :class="{item_unvalid:!checkItemIsValid(item),order_import_item:true}">
          <div>
            <parser ref="formRefs"
              :form-conf="readonlySchemaDesc"
              :formModel="item">
            </parser>
          </div>
          <div class="d-flex justify-content-between align-items-center"  @click="handleItemEdit(index,item)">
            <div class="font-small text-danger pl-1">
              <span :style="{visibility:checkItemIsValid(item)?'hidden':'visible',cursor:'pointer'}">
                <i  class="el-icon-warning-outline mr-1"></i>
                隐藏内容存在错误信息，请检查
              </span>
            </div>
            <i class="el-icon-caret-bottom"></i>
            <div>
              <el-button type="text" icon="el-icon-edit-outline">编辑</el-button>
              <el-button type="text" icon="el-icon-delete" @click.stop="handleItemDelete(index,item)">删除</el-button>
            </div>
          </div>
        </div>
       </VirtualScrollList>
    </div>
    <div class="empty_list" v-else >
      <img :src="require('../../assets/imgs/order/import_empty.png')" alt="">
      <el-button type="primary" class="mt-3" style="width:200px" @click="importDialogVisible=true">导入数据</el-button>
      <el-dropdown class="mt-2 mb-4" @command="downloadExcelTemplate">
        <el-button type="text">
          下载模板<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown" style="max-height': '260px', 'overflow-y': 'auto'">
          <el-dropdown-item v-for="i in excelTemplateList"
            :key="i.code"
            :command="i">
            {{i.name}}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <bottom-panel v-if="orderList && orderList[0]" >
        <el-dropdown  class="mr-2" @command="downloadExcelTemplate">
          <el-button type="text">
            下载模板<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown" style="max-height': '260px', 'overflow-y': 'auto'">
            <el-dropdown-item v-for="i in excelTemplateList"
              :key="i.code"
              :command="i">
              {{i.name}}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button plain class="mr-2" @click="$router.go(-1)">返回</el-button>
        <el-button type="primary" plain class="mr-2" @click="importDialogVisible=true">重新上传</el-button>
        <el-button type="primary" :disabled="orderList.some(t=>!this.checkItemIsValid(t))" @click="handleSaveImportOrder">保存</el-button>
    </bottom-panel>
    <el-dialog title="导入数据"
      width="500px"
      :visible.sync="importDialogVisible">
      <div v-loading="isUploading" class="text-align-center" element-loading-text="文件上传中请稍候...">
        <el-upload drag
          :show-file-list="false"
          :headers="{Authorization:token}"
          :before-upload="handleUpload"
          :on-success="handleUploadSuccess"
          :action="uploadActionCommon" >
          <div class="upload_icon" >
            <img :src="require('../../assets/imgs/order/upload_icon.png')" alt="">
          </div>
          <div class="el-upload__text"><em>将数据文件拖拽至框内上传，或点击上传</em></div>
        </el-upload>
      </div>
    </el-dialog>
    <el-dialog title="编辑"
      v-if="editDialogVisible"
      width="1400px"
      :close-on-click-modal="false"
      @close="editDialogVisible = false"
      :visible="true">
        <parser ref="editFormRef"
          :form-conf="schemaDesc"
          :formModel="currentEditForm">
        </parser>
        <span slot="footer">
          <el-button @click="editDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="handleItemSave">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>
<script>
import AsyncValidator from 'async-validator';
import VirtualScrollList from 'vue-virtual-scroll-list';
import { getFormTypeListByConfigId, getSchemaWithExcelTemplate, batchSaveCommonForm } from '@/api/commonForm';
import Parser from '@/components/form-generator/parser/index.js';
import { getDefaultValueByTag, buildRules, flatternFields } from '@/components/form-generator/utils/component.js';
import _ from 'lodash';
import downloadFile from '@/utils/download';
export default {
  name: 'order_import',
  components: {
    Parser,
    VirtualScrollList,
  },
  props: {
    // WORK_ORDER 工单
    // DEVICE_FILE 设备
    type: {
      type: String,
      default: 'WORK_ORDER',
    },
  },
  data () {
    const token = localStorage.getItem('token');
    return {
      formRefs: {},
      formValidState: new WeakMap(),
      orderList: [],
      importDialogVisible: false,
      token,
      downloadLink: `${process.env.VUE_APP_HOST}workOrderTemplate.xlsx`,
      uploadActionCommon: `${process.env.VUE_APP_HOST}xiaozi-xmb/wireless/saas/customize/printTemplate/templateImport`,
      isUploading: false,
      schemaDesc: null,
      templateType: null,
      editDialogVisible: false,
      currentEditForm: null,
      currentEditItemIndex: -1,
      remainCount: 0,
      importContainer: {},
      isImporting: false,
      excelTemplateList: [],
    };
  },
  methods: {
    downloadExcelTemplate (command) {
      getSchemaWithExcelTemplate({
        customizeBusinessTypeCode: command.code,
        customizeSystemConfigCode: this.type,
        systemConfigCodeFlag: this.type,
      }).then(({data})=>{
        const type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        const fileTitle = command.name;
        downloadFile({
          data,
          type,
          fileTitle,
        });
      });
    },
    handleListScrolled: _.debounce(function () {
      // 滚动时校验当前渲染的表单项规则
      this.$nextTick(()=>{
        this.$refs.formRefs && this.$refs.formRefs.forEach((form)=>{
          form.validForm();
        });
      });
    }, 500),
    handleUpload () {
      this.isUploading = true;
      return Promise.resolve();
    },
    checkItemIsValid (item) {
      return this.formValidState.get(item);
    },
    resetOrderList () {
      this.formValidState = new WeakMap();
      this.orderList.splice(0, this.orderList.length);
    },
    handleUploadSuccess (response) {
      this.isUploading = false;
      this.importDialogVisible = false;

      if (response.heads && response.heads.code === 200) {
        this.resetOrderList();
        this.schemaDesc = response.body.nodes.customizeBusinessProcessResDTO.customizeLayoutResDTO.schemaDesc;
        const fieldMap = this.getFormFieldListMap();
        const fieldBindedList = [];
        fieldMap && Object.keys(fieldMap).forEach(key=>{
          let fieldItem = fieldMap[key];
          if (fieldItem?.__config__?.from && fieldItem?.__config__?.from?.key) {
            fieldBindedList.push(fieldItem);
          }
        });
        const setBindValue = (model)=>{
          fieldBindedList.forEach(fieldItem=>{
            if (model[fieldItem?.__config__?.renderKey] === null || model[fieldItem?.__config__?.renderKey] === undefined) {
              const val = model[fieldItem?.__config__?.from?.key];
              if (val !== null && val !== undefined) {
                model[fieldItem?.__config__?.renderKey] = val[fieldItem?.__config__?.from?.prop];
              }
            }
          });
        };
        response.body.workOrderMap.forEach((t, index)=>{
          // 虚拟key，在提交保存时会删除
          let orderModel = {__key__: `${new Date().getTime()}-${index}`};
          this.setDefaultViewModel(this.schemaDesc?.fields, orderModel);
          Object.keys(t).forEach(key=>{
            if (t[key] !== undefined) {
              orderModel[key] = t[key];
            }
          });
          setBindValue(orderModel);
          this.orderList.push(orderModel);
        });
        this.validOrderListState(fieldMap);
        this.$refs.refScroll && this.$refs.refScroll.forceRender();
        this.$nextTick(()=>{
          this.$refs.formRefs && this.$refs.formRefs.forEach((form)=>{
            form.validForm();
          });
        });
        this.$message.success('导入完成');
      } else {
        this.$alert(`<div style="max-height:600px;overflow-y:auto">${response.heads.message}</div>`, {
          title: '导入失败',
          type: 'error',
          dangerouslyUseHTMLString: true,
          duration: 0,
          showClose: true,
        });
      }
    },
    handleItemDelete (index, item) {
      this.$confirm('确认删除该条数据？', {type: 'warning'}).then(()=>{
        this.formValidState.delete(item);
        this.orderList.splice(index, 1);
      });
    },
    handleItemEdit (index, item) {
      this.currentEditItemIndex = index;
      this.currentEditForm = _.cloneDeep(item);
      this.editDialogVisible = true;
      this.$nextTick(()=>{
        this.$refs.editFormRef.validForm();
      });
    },
    handleItemSave () {
      this.$refs.editFormRef.validForm().then(valid=>{
        if (valid) {
          this.editDialogVisible = false;
          this.formValidState.delete(this.orderList[this.currentEditItemIndex]);
          this.currentEditForm.__key__ = `${new Date().getTime()}-${this.currentEditItemIndex}`;
          this.orderList.splice(this.currentEditItemIndex, 1, this.currentEditForm);
          this.formValidState.set(this.orderList[this.currentEditItemIndex], true);
        }
      });
    },
    setDefaultViewModel (nodeList, orderModel) {
      nodeList && nodeList.forEach(node=>{
        if (node?.__config__?.layout === 'groupRowFormItem') {
          this.setDefaultViewModel(node?.__config__?.children, orderModel);
        } else {
          orderModel[node.__config__.renderKey] = getDefaultValueByTag(node);
        }
      });
    },
    handleSaveImportOrder () {
      this.isImporting = true;
      let action = batchSaveCommonForm(
        {
          customizeFields: this.orderList.map(t=>({...t, __key__: null})),
          customizeSystemConfigCode: this.type,
          systemConfigCodeFlag: this.type,
        },
        {
          timeout: null,
          stopDefaultLoading: true,
        },
      );

      action.then(()=>{
        this.$message.success('批量保存成功');
        this.$router.go(-1);
      }).finally(()=>{
        this.isImporting = false;
      });
    },
    getFormFieldListMap () {
      let filedMap = {};
      flatternFields(this.schemaDesc.fields, filedMap);
      return filedMap;
    },
    validOrderListState (filedMap) {
      // js 校验当前列表所有项状态
      let curretFormRules = {};
      buildRules(this.schemaDesc.fields, curretFormRules, filedMap);

      const validator = new AsyncValidator(curretFormRules);
      this.orderList.forEach(model=>{
        validator.validate(model, { firstFields: true }, (errors)=>{
          if (errors && errors[0]) {
            this.formValidState.set(model, false);
          } else {
            this.formValidState.set(model, true);
          }
        });
      });
    },
    calcRemainCount () {
      let rect = this.$refs.importContainer.getBoundingClientRect();
      this.remainCount = (rect.height - 60) / 216;
    },
  },
  computed: {
    readonlySchemaDesc () {
      return Object.assign({}, this.schemaDesc, {disabled: true});
    },
    routeTab () {
      return this.type === 'WORK_ORDER' ? '批量导入工单' : '批量导入设备';
    },
  },
  mounted () {
    window.addEventListener('resize', this.calcRemainCount);
    this.calcRemainCount();
    getFormTypeListByConfigId({
      customizeSystemConfigCode: this.type,
    }).then(res=>{
      this.excelTemplateList = res.body?.filter(t=>t.status) || [];
    });
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.calcRemainCount);
  },
};
</script>

<style lang="scss" scoped>
.order_import {
  height: 100%;
  display: flex;
  flex-direction: column;
  .order_import_list {
    flex: 1;
    overflow: auto;
    div.order_import_item{
      position: relative;
      height: 200px;
      box-sizing: border-box;
      background: #FFFFFF;
      box-shadow: 0px 2px 8px 0px rgba(36,40,50,0.14);
      border-radius: 4px;
      border: 1px solid rgba(113,121,141,0.16);
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;
      &>div{
        &:first-child {
          flex: 1;
          overflow: hidden;
        }

        &:last-child {
          border-top: 1px solid #E4E5E6;
          padding-right: 12px;
          height: 32px;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          &:hover {
            color: rgba(15,137,255,0.08);
            background: rgba(15,137,255,0.08);
          }
        }
      }

       &.item_unvalid {
        box-shadow: 0px 2px 8px 0px rgba(36,40,50,0.14);
        border-radius: 4px;
        border: 1px solid rgba(252,52,52,0.6);
      }
    }
  }
  .empty_list {
    height: 282px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px 0px rgba(36,40,50,0.14);
    border-radius: 4px;
    border: 1px solid rgba(113,121,141,0.16);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }

   /deep/.el-upload-dragger {
    width: 383px;
    height: 118px;
    margin-top: 24px;
    margin-bottom: 50px;

    .upload_icon {
      display:inline-block;
      line-height: 56px;
      font-size: 0px;
      width:56px;
      height:56px;
      border-radius: 50%;
      background: #FFFFFF;
      box-shadow: 0px 2px 4px 0px rgba(36,40,50,0.1);
      border: 1px solid rgba(113,121,141,0.08);
      margin-bottom: 12px;
      margin-top: 16px;
      &>img {
        vertical-align: middle;
      }

    }
  }
}
</style>
